import React, { useState } from "react";
import { Box } from "@mui/material";
import NavBar from "./NavBar";
import Router from "./Router";
import Sidebar from "./Sidebar";

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleDrawer = () => setSidebarOpen(!sidebarOpen);

  const [height, setHeight] = React.useState(0);
  const ref = React.useRef();

  React.useEffect(() => {
    setHeight(ref.current?.offsetHeight);
  }, [ref.current?.offsetHeight]);

  return (
    <Box sx={{ height: "100vh" }}>
      <NavBar ref={ref} toggleDrawer={toggleDrawer} />
      <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          height: `calc(100% - ${height}px)`,
          overflow: "scroll",
          width: "100%",
        }}
      >
        <Router />
      </Box>
    </Box>
  );
}
