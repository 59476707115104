export const convertToMap = (listOfObjects, key) => {
  return listOfObjects.reduce((acc, item, index) => {
    acc[key ? item[key] : index] = item;
    return acc;
  }, {});
};

export const unique = (listOfObjects, key) => {
  return Object.values(convertToMap(listOfObjects, key));
};
