import { Box, Button, CircularProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteDirectJob, getAllDirectJobs } from "../api/job";
import DirectJobCard from "../components/DirectJobCard";
import Empty from "../components/utils/Empty";
import withAuth from "../hoc/withAuth";
import routes from "../layout/routes";

const DirectJobsListForRecruiter = () => {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const res = await getAllDirectJobs();
    const { jobs } = res.data;
    setJobs(jobs);
    setLoading(false);
  };

  const onDelete = async (jobId) => {
    try {
      await deleteDirectJob(jobId);
      setJobs(jobs.filter((job) => job._id !== jobId));
    } catch (err) {
      throw err;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={(theme) => ({
          width: "60%",
          height: "100%",
          padding: 2,
          flexGrow: 1,
          flexDirection: "column",
          overflow: "auto",
          alignSelf: "center",
          alignItems: "center",
          display: "flex",
          [theme.breakpoints.only("md")]: {
            width: "80%",
            padding: 1,
          },
          [theme.breakpoints.down("md")]: {
            width: "90%",
            padding: 1,
          },
        })}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography variant="h4" component="div">
                Jobs
              </Typography>

              <Button
                variant="contained"
                sx={{ ml: "auto" }}
                onClick={() => navigate(routes.createDirectJob.path)}
              >
                Add Job
              </Button>
            </Box>
            {jobs.length === 0 ? (
              <Empty message="There are no active jobs! Try adding new job." />
            ) : (
              jobs
                .sort((a, b) =>
                  dayjs(a.startTime) > dayjs(b.startTime) ? -1 : 1
                )
                .map((job) => (
                  <DirectJobCard key={job._id} obj={job} onDelete={onDelete} />
                ))
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default withAuth(DirectJobsListForRecruiter);
