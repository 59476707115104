import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import withAuth from "../../hoc/withAuth";
import routes from "../../layout/routes";

const DirectJobCard = ({ obj, isRecruiter, onDelete }) => {
  const navigate = useNavigate();

  const goToDetails = () => {
    navigate(routes.jobDetails.getPath(obj._id));
  };

  const onEdit = () => {
    navigate(routes.editJob.getPath(obj._id));
  };

  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        overflow: "visible",
        m: 2,
      }}
      variant="outlined"
    >
      <CardHeader
        action={
          isRecruiter && (
            <Box
              sx={{
                width: "20%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <IconButton onClick={onEdit}>
                <EditIcon />
              </IconButton>
              <Divider orientation="vertical" variant="middle" flexItem />
              <IconButton onClick={() => onDelete(obj._id)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          )
        }
        title={obj.title}
      />
      <CardContent sx={{ height: "min-content" }}>
        <Typography variant="body2" gutterBottom component="div">
          Location: {obj.location}
        </Typography>
        <Typography variant="body2" gutterBottom component="div">
          Salary: {obj.salary} (LPA)
        </Typography>
      </CardContent>
      <CardActions sx={{ marginTop: "auto", height: "auto" }}>
        <Button size="small" onClick={goToDetails}>
          Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default withAuth(DirectJobCard);
