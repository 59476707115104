import { delReq, getReq, postReq, putReq } from ".";

export const getAllJobs = () => {
  return getReq(`/jobs`);
};

export const getJobDetails = (jobId) => {
  return getReq(`/jobs?jobId=${jobId}`);
};

export const createJob = (job) => {
  return postReq(`/jobs`, { job });
};

export const updateJob = (id, job) => {
  return putReq(`/jobs/${id}`, { job });
};

export const deleteJob = (id) => {
  return delReq(`/jobs/${id}`);
};

export const getAllDirectJobs = () => {
  return getReq(`/direct-jobs`);
};

export const createDirectJob = (job) => {
  return postReq(`/direct-jobs`, { job });
};

export const updateDirectJob = (id, job) => {
  return putReq(`/direct-jobs/${id}`, { job });
};

export const deleteDirectJob = (id) => {
  return delReq(`/direct-jobs/${id}`);
};

export const getApplicationsOfJob = (jobId) => {
  return getReq(`/applications/${jobId}`);
};

export const applyToJob = (jobId) => {
  return postReq(`/applications`, { jobId });
};

export const getMyApplications = () => {
  return getReq(`/my-applications`);
};

export const updateApplicationStatus = ({ userId, jobId, status }) => {
  return putReq(`/applications`, { userId, jobId, status });
};
