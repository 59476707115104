import { CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getStudent } from "../../api/user";

const AboutStudent = ({ studentId }) => {
  const [student, setStudent] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getStudent(studentId)
      .then((res) => {
        setStudent(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [studentId]);

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          <Typography variant="h6">{student.name}</Typography>

          <Typography variant="h6">Projects</Typography>
          {student.experience.map((exp) => (
            <div key={exp._id}>
              <Typography variant="h6">{exp.title}</Typography>
              <Typography variant="body1">{exp.company}</Typography>
              <Typography variant="body1">
                {exp.startDate} - {exp.endDate}
              </Typography>
              <Typography variant="body1">{exp.details}</Typography>
            </div>
          ))}

          {student.projects.map((project) => (
            <div key={project._id}>
              <Typography variant="h6">{project.title}</Typography>
              <Typography variant="body1">{project.description}</Typography>
            </div>
          ))}

          <Typography variant="h6">Education</Typography>
          {student.education.map((ed) => (
            <Typography key={ed._id} variant="body1">
              <Typography variant="h6">{ed.degree}</Typography>
              <Typography variant="body1">{ed.college}</Typography>
              <Typography variant="body1">{ed.endDate}</Typography>
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
};

export default AboutStudent;
