import withAuth from "../hoc/withAuth";
import DirectJobsListForRecruiter from "./DirectJobsListForRecruiter";
import JobsList from "./JobsList";

const Home = ({ isRecruiter }) => {
  if (isRecruiter) {
    return <DirectJobsListForRecruiter />;
  }
  return <JobsList />;
};

export default withAuth(Home);
