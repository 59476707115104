import { useEffect, useRef, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const styles = {
  field: {
    mt: 1,
    mb: 1,
  },
  editor: {
    margin: "1rem",
  },
};

const JobForm = ({ onSubmit, obj }) => {
  const [job, setJob] = useState({
    title: "",
    description: "",
    salary: "",
    location: "",
    companyName: "",
    companyDescription: "",
    companyLogo: "",
    applyLink: "",
  });
  const [errors, setErrors] = useState({});

  const errorRef = useRef(null);

  useEffect(() => {
    if (obj) {
      setJob(obj);
    }
  }, [obj]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJob({ ...job, [name]: value });
  };

  const handleSubmit = async () => {
    setErrors([]);
    try {
      await onSubmit(job);
    } catch (err) {
      if (err.response?.data?.message) {
        setErrors([err.response.data.message]);
      } else {
        setErrors(["Unknown error"]);
      }
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box ref={errorRef} sx={{ width: "100%" }}>
      {errors?.length > 0 && (
        <Alert severity="error" sx={{ m: 1, width: "auto", mt: 2, mb: 4 }}>
          <AlertTitle>Error</AlertTitle>
          <ul>
            {errors.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
      <FormControl sx={{ width: "100%" }}>
        <TextField
          label="Title"
          value={job.title}
          onChange={handleChange}
          sx={styles.field}
          name="title"
        />

        <TextField
          label="Company Name"
          variant="outlined"
          value={job.companyName}
          onChange={handleChange}
          name="companyName"
          sx={styles.field}
        />
        <TextField
          label="Company Logo"
          variant="outlined"
          value={job.companyLogo}
          onChange={handleChange}
          name="companyLogo"
          sx={styles.field}
        />
        <Box sx={styles.field}>
          <CKEditor
            config={{ placeholder: "Company Description" }}
            editor={ClassicEditor}
            data={job.companyDescription}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              // console.log({ data });
              setJob({ ...job, companyDescription: data });
            }}
          />
        </Box>

        <Box sx={styles.field}>
          <CKEditor
            editor={ClassicEditor}
            config={{ placeholder: "Job Description" }}
            data={job.description}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              // console.log({ data });
              setJob({ ...job, description: data });
            }}
          />
        </Box>
        <TextField
          label="Job Location"
          variant="outlined"
          value={job.location}
          onChange={handleChange}
          name="location"
          sx={styles.field}
        />
        <TextField
          label="Salary in LPA"
          variant="outlined"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={job.salary}
          onChange={handleChange}
          name="salary"
          sx={styles.field}
        />

        <TextField
          label="Link to apply"
          variant="outlined"
          value={job.applyLink}
          onChange={handleChange}
          name="applyLink"
          sx={styles.field}
        />

        <Button
          variant="contained"
          sx={{ mt: 1 }}
          onClick={handleSubmit}
          size="large"
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  );
};

export default JobForm;
