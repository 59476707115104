import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const DialogX = ({
  open,
  handleClose,
  title,
  content,
  primaryAction,
  primaryActionTitle = "Ok",
  secondaryAction,
  secondaryActionTitle = "Close",
  maxWidth = "md",
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        {secondaryAction && (
          <Button onClick={secondaryAction}>{secondaryActionTitle}</Button>
        )}
        {primaryAction && (
          <Button variant="contained" onClick={primaryAction}>
            {primaryActionTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogX;
