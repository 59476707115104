import CreateDirectJob from "../pages/CreateDirectJob";
import CreateJob from "../pages/CreateJob";
import DirectJobsListForRecruiter from "../pages/DirectJobsListForRecruiter";
import EditDirectJob from "../pages/EditDirectJob";
import EditJob from "../pages/EditJob";
import Home from "../pages/Home";
import JobDetails from "../pages/JobDetails";
import JobsList from "../pages/JobsList";
import MyApplications from "../pages/MyApplications";
import { USER_ROLES } from "../utils/constants";

const { SUPERADMIN, COLLEGE_ADMIN, COMPANY_ADMIN, STUDENT, STAFFADMIN } =
  USER_ROLES;

const routes = {
  home: {
    title: "Home",
    path: "/",
    component: <Home />,
    for: [SUPERADMIN, COLLEGE_ADMIN, COMPANY_ADMIN, STUDENT, STAFFADMIN],
    sidebar: true,
  },
  createJob: {
    title: "Create Job",
    path: "/jobs/create",
    component: <CreateJob />,
    for: [SUPERADMIN],
  },
  editJob: {
    title: "Edit Job",
    _path: "/jobs/edit",
    get path() {
      return `${this._path}/:jobId`;
    },
    getPath: function (id) {
      return `${this._path}/${id}`;
    },
    component: <EditJob />,
    for: [SUPERADMIN],
  },
  jobsList: {
    title: "Jobs",
    path: "/jobs",
    component: <JobsList />,
    for: [SUPERADMIN, COLLEGE_ADMIN, STUDENT, STAFFADMIN],
    sidebar: true,
  },
  jobDetails: {
    title: "Job Details",
    _path: "/job",
    get path() {
      return `${this._path}/:jobId`;
    },
    getPath: function (id) {
      return `${this._path}/${id}`;
    },
    component: <JobDetails />,
    for: [SUPERADMIN, COLLEGE_ADMIN, COMPANY_ADMIN, STUDENT, STAFFADMIN],
  },
  directJobsListForRecruiter: {
    title: "Jobs",
    path: "/jobs",
    component: <DirectJobsListForRecruiter />,
    for: [COMPANY_ADMIN],
  },
  createDirectJob: {
    title: "Create Job",
    path: "/jobs/create",
    component: <CreateDirectJob />,
    for: [COMPANY_ADMIN],
  },
  editDirectJob: {
    title: "Edit Job",
    _path: "/jobs/edit",
    get path() {
      return `${this._path}/:jobId`;
    },
    getPath: function (id) {
      return `${this._path}/${id}`;
    },
    component: <EditDirectJob />,
    for: [COMPANY_ADMIN],
  },
  myApplications: {
    title: "My Applications",
    path: "/applications",
    component: <MyApplications />,
    for: [COLLEGE_ADMIN, STUDENT],
    sidebar: true,
  },
};

export default routes;
