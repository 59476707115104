import { Box, Button, CircularProgress, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteJob, getAllJobs, getMyApplications } from "../api/job";
import JobCard from "../components/JobCard";
import Empty from "../components/utils/Empty";
import withAuth from "../hoc/withAuth";
import routes from "../layout/routes";

const JobsList = ({ isSuperAdmin }) => {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [applications, setApplications] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    init();
    fetchMyApplications();
  }, []);

  const init = async () => {
    const res = await getAllJobs();
    const { jobs } = res.data;
    setJobs(jobs);
    setLoading(false);
  };

  const fetchMyApplications = async () => {
    const applicationsRes = await getMyApplications();
    const { applications } = applicationsRes.data;
    const appliedJobsMap = applications
      .map(({ jobId, status }) => ({ jobId, status }))
      .reduce((acc, cur) => {
        acc[cur.jobId] = cur;
        return acc;
      }, {});
    setApplications(appliedJobsMap);
  };

  const onDelete = async (jobId) => {
    try {
      await deleteJob(jobId);
      setJobs(jobs.filter((job) => job._id !== jobId));
    } catch (err) {
      throw err;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={(theme) => ({
          width: "60%",
          height: "100%",
          padding: 2,
          flexGrow: 1,
          flexDirection: "column",
          overflow: "auto",
          alignSelf: "center",
          alignItems: "center",
          display: "flex",
          [theme.breakpoints.only("md")]: {
            width: "80%",
            padding: 1,
          },
          [theme.breakpoints.down("md")]: {
            width: "90%",
            padding: 1,
          },
        })}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography variant="h4" component="div">
                Jobs
              </Typography>
              {isSuperAdmin && (
                <Button
                  variant="contained"
                  sx={{ ml: "auto" }}
                  onClick={() => navigate(routes.createJob.path)}
                >
                  Add Job
                </Button>
              )}
            </Box>
            {jobs.length === 0 ? (
              <Empty message="No jobs available at the moment! Please check after some time." />
            ) : (
              jobs
                .sort((a, b) =>
                  dayjs(a.startTime) > dayjs(b.startTime) ? -1 : 1
                )
                .map((job) => (
                  <JobCard
                    key={job._id}
                    obj={job}
                    onDelete={onDelete}
                    applied={applications[job._id]}
                    applicationStatus={applications[job._id]?.status}
                  />
                ))
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default withAuth(JobsList);
