import { Box, Typography } from "@mui/material";
import JobForm from "../components/JobForm";
import { createJob } from "../api/job";
import { useNavigate } from "react-router-dom";
import routes from "../layout/routes";

const CreateJob = () => {
  const navigate = useNavigate();

  const onSubmit = async (job) => {
    try {
      const res = await createJob(job);
      navigate(routes.jobDetails.getPath(res.data._id));
    } catch (err) {
      throw err;
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pl: "20%",
        pr: "20%",
        mt: 2,
        mb: 2,
        pb: 2,
        [theme.breakpoints.down("md")]: {
          pl: "10%",
          pr: "10%",
        },
        [theme.breakpoints.down("sm")]: {
          pl: 2,
          pr: 2,
        },
      })}
    >
      <Typography variant="h4" sx={{ alignSelf: "flex-start" }}>
        Create Job
      </Typography>
      <JobForm onSubmit={onSubmit} />
    </Box>
  );
};

export default CreateJob;
