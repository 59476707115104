import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import Account from "./Account";
import Logo from "./Logo";

export const MenuButton = ({ onClick }) => {
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={onClick}
    >
      <MenuIcon />
    </IconButton>
  );
};

const NavBar = React.forwardRef(({ toggleDrawer }, ref) => {
  return (
    <Box ref={ref} sx={{ flexGrow: 1, backgroundColor: grey["100"] }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <MenuButton onClick={toggleDrawer} />
          <Logo />
          <Account />
        </Toolbar>
      </AppBar>
    </Box>
  );
});

export default NavBar;
