import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getMyApplications } from "../api/job";
import TableX from "../components/utils/TableX";
import routes from "../layout/routes";
import { APPLICATION_STATUS } from "../utils/constants";

const columns = [
  {
    title: "Job Title",
    field: "job",
    render: (v, row) => (
      <Link to={routes.jobDetails.getPath(row.jobId)}>{v?.title}</Link>
    ),
    width: "50%",
  },
  {
    title: "Status",
    field: "status",
    width: "20%",
    render: (v) => (
      <Typography color={APPLICATION_STATUS[v]?.color}>
        {APPLICATION_STATUS[v]?.label}
      </Typography>
    ),
    filterValue: (row) => ({
      value: row?.status,
      title: APPLICATION_STATUS[row?.status]?.label,
    }),
  },
  {
    title: "Applied On",
    field: "createdAt",
    width: "30%",
    render: (v) => dayjs(v).format("DD MMM YYYY"),
    onSort: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  },
];

const MyApplications = () => {
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    setLoading(true);
    const res = await getMyApplications();
    const { applications } = res.data;
    setApplications(applications);
    setLoading(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={(theme) => ({
          width: "60%",
          height: "100%",
          padding: 2,
          flexGrow: 1,
          flexDirection: "column",
          overflow: "auto",
          alignSelf: "center",
          alignItems: "center",
          display: "flex",
          [theme.breakpoints.only("md")]: {
            width: "80%",
            padding: 1,
          },
          [theme.breakpoints.down("md")]: {
            width: "90%",
            padding: 1,
          },
        })}
      >
        <TableX
          title="Applications"
          loading={isLoading}
          action={
            <LoadingButton
              loading={isLoading}
              variant="text"
              onClick={fetchApplications}
            >
              Refresh
            </LoadingButton>
          }
          showIndexColumn
          keyExtractor={(r) => r._id}
          style={{ width: "100%" }}
          tableStyle={{ width: "100%", mx: 0 }}
          columns={columns}
          rows={applications}
        />
      </Box>
    </Box>
  );
};

export default MyApplications;
