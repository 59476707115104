import { Box, Typography } from "@mui/material";
import JobForm from "../components/JobForm";
import { getJobDetails, updateJob } from "../api/job";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "../components/utils/Loading";
import routes from "../layout/routes";

const EditJob = () => {
  const { jobId } = useParams();

  const [job, setJob] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getJobDetails(jobId)
      .then((res) => {
        setJob(res.data.job);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jobId]);

  const onSubmit = async (job) => {
    try {
      const res = await updateJob(jobId, job);
      navigate(routes.jobDetails.getPath(res.data.job._id));
    } catch (err) {
      throw err;
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pl: "20%",
        pr: "20%",
        mt: 2,
        mb: 2,
        pb: 2,
        [theme.breakpoints.down("md")]: {
          pl: "10%",
          pr: "10%",
        },
        [theme.breakpoints.down("sm")]: {
          pl: 2,
          pr: 2,
        },
      })}
    >
      <Typography variant="h4" sx={{ alignSelf: "flex-start" }}>
        Edit Job
      </Typography>
      {loading ? <Loading /> : <JobForm obj={job} onSubmit={onSubmit} />}
    </Box>
  );
};

export default EditJob;
