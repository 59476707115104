export const USER_ROLES = {
  STUDENT: "student",
  COLLEGE_ADMIN: "admin",
  COMPANY_ADMIN: "company_admin",
  STAFFADMIN: "staffadmin",
  SUPERADMIN: "superadmin",
};

export const APPLICATION_STATUS = {
  pending: { label: "Applied", color: "blue" },
  shortlisted: { label: "Shortlisted", color: "green" },
  rejected: { label: "Rejected", color: "red" },
  hired: { label: "Hired", color: "info" },
};
