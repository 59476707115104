import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const styles = {
  field: {
    mt: 1,
    mb: 1,
  },
  editor: {
    margin: "1rem",
  },
};

const JobForm = ({ onSubmit, obj }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [salary, setSalary] = useState("");
  const [location, setLocation] = useState("");

  const [errors, setErrors] = useState({});

  const errorRef = useRef(null);

  useEffect(() => {
    if (obj) {
      console.log(obj);
      setTitle(obj.title);
      setDescription(obj.description);
      setSalary(obj.salary);
      setLocation(obj.location);
    }
  }, [obj]);

  const handleChange = (setFunc) => (e) => {
    const { value } = e.target;
    setFunc(value);
  };

  const handleSubmit = async () => {
    setErrors([]);
    try {
      await onSubmit({
        title,
        description,
        salary,
        location,
      });
    } catch (err) {
      if (err.response?.data?.message) {
        setErrors([err.response.data.message]);
      } else {
        setErrors(["Unknown error"]);
      }
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box ref={errorRef} sx={{ width: "100%" }}>
      {errors?.length > 0 && (
        <Alert severity="error" sx={{ m: 1, width: "auto", mt: 2, mb: 4 }}>
          <AlertTitle>Error</AlertTitle>
          <ul>
            {errors.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
      <FormControl sx={{ width: "100%" }}>
        <TextField
          label="Title"
          value={title}
          onChange={handleChange(setTitle)}
          sx={styles.field}
          name="title"
        />

        <Box sx={styles.field}>
          <CKEditor
            editor={ClassicEditor}
            config={{ placeholder: "Job Description" }}
            data={description}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              // console.log({ data });
              setDescription(data);
            }}
          />
        </Box>

        <TextField
          label="Job Location"
          variant="outlined"
          value={location}
          onChange={handleChange(setLocation)}
          name="location"
          sx={styles.field}
        />
        <TextField
          label="Salary in LPA"
          variant="outlined"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={salary}
          onChange={handleChange(setSalary)}
          name="salary"
          sx={styles.field}
        />

        <Button
          variant="contained"
          sx={{ mt: 1 }}
          onClick={handleSubmit}
          size="large"
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  );
};

export default JobForm;
