import { useContext } from "react";
import { AuthContext } from "../App";

const withAuth = (Component) => {
  return (props) => {
    const {
      state: { isAuthenticated, user },
    } = useContext(AuthContext);
    const isSuperAdmin = isAuthenticated && user?.role === "superadmin";
    const isAdmin = isAuthenticated && user?.role === "admin";
    const isRecruiter = isAuthenticated && user?.role === "company_admin";

    return (
      <Component
        {...props}
        isAuthenticated={isAuthenticated}
        user={user}
        isAdmin={isAdmin}
        isSuperAdmin={isSuperAdmin}
        isRecruiter={isRecruiter}
      />
    );
  };
};

export default withAuth;
