import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Divider, IconButton, InputBase, Paper } from "@mui/material";

const SearchBar = ({ value, onChange, placeholder, onSubmit }) => {
  return (
    <Paper
      variant="outlined"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
    >
      <SearchIcon sx={{ mx: 1 }} />
      <Divider sx={{ height: 28, mr: 0.5 }} orientation="vertical" />
      <InputBase
        sx={{ flex: 1, p: "2px 4px" }}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onSubmit?.();
          }
        }}
        inputProps={{ "aria-label": "search" }}
      />
      {value && (
        <>
          <IconButton sx={{ p: "2px" }} type="button" aria-label="search">
            <ClearIcon onClick={() => onChange("")} />
          </IconButton>
          {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
        </>
      )}
      {/* <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon onSearch={onSearch} />
      </IconButton> */}
    </Paper>
  );
};

export default SearchBar;
