import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useState } from "react";
import AboutStudent from "../AboutStudent";
import DialogX from "../utils/DialogX";
import TableX from "../utils/TableX";

import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { getApplicationsOfJob, updateApplicationStatus } from "../../api/job";
import { APPLICATION_STATUS } from "../../utils/constants";
import { convertToMap } from "../../utils/object";

const columns = [
  {
    title: "Name",
    field: "user",
    render: (v) => v?.name,
    width: "30%",
  },
  {
    title: "Status",
    field: "status",
    width: "20%",
    render: (v) => (
      <Typography color={APPLICATION_STATUS[v]?.color}>
        {APPLICATION_STATUS[v]?.label}
      </Typography>
    ),
    filterValue: (row) => ({
      value: row?.status,
      title: APPLICATION_STATUS[row?.status]?.label,
    }),
  },
  {
    title: "Applied On",
    field: "createdAt",
    width: "30%",
    render: (v) => dayjs(v).format("DD MMM YYYY"),
  },
];

const JobApplications = ({ jobId }) => {
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedApplication, setSelectedApplication] = useState(null);

  useEffect(() => {
    fetchApplications();
  }, [jobId]);

  const fetchApplications = async () => {
    setLoading(true);
    getApplicationsOfJob(jobId)
      .then((res) => {
        setApplications(convertToMap(res.data, "_id"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const shortlistApplication = () => {
    updateApplicationStatus({
      userId: applications[selectedApplication].user._id,
      jobId,
      status: "shortlisted",
    })
      .then(() => setSelectedApplication(null))
      .then(() => fetchApplications());
  };
  const rejectApplication = () => {
    updateApplicationStatus({
      userId: applications[selectedApplication].user._id,
      jobId,
      status: "rejected",
    })
      .then(() => setSelectedApplication(null))
      .then(() => fetchApplications());
  };

  return (
    <>
      <DialogX
        open={Boolean(selectedApplication)}
        handleClose={() => setSelectedApplication(null)}
        title="Application Details"
        content={
          selectedApplication && (
            <AboutStudent
              studentId={applications[selectedApplication].user._id}
            />
          )
        }
        primaryAction={() => shortlistApplication()}
        primaryActionTitle="Shortlist"
        secondaryAction={() => rejectApplication()}
        secondaryActionTitle="Reject"
      />
      <TableX
        title="Applications"
        loading={isLoading}
        action={
          <LoadingButton
            loading={isLoading}
            variant="text"
            onClick={fetchApplications}
          >
            Refresh
          </LoadingButton>
        }
        showIndexColumn
        keyExtractor={(r) => r._id}
        style={{ width: "auto" }}
        tableStyle={{ width: "100%", mx: 0 }}
        columns={columns}
        rows={Object.values(applications)}
        actions={[
          {
            title: "View",
            icon: <InfoOutlinedIcon fontSize="small" />,
            onClick: setSelectedApplication,
          },
        ]}
      />
    </>
  );
};

export default JobApplications;
