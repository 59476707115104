import { Route, Routes } from "react-router-dom";
import withAuth from "../hoc/withAuth";
import routes from "./routes";

const Router = ({ user }) => {
  return (
    <Routes>
      {Object.keys(routes)
        .filter((route) => routes[route].for.includes(user?.role))
        .map((key) => (
          <Route
            key={key}
            path={routes[key].path}
            element={routes[key].component}
          />
        ))}
    </Routes>
  );
};

export default withAuth(Router);
