import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { applyToJob, getJobDetails, getMyApplications } from "../api/job";
import JobApplications from "../components/JobApplications";
import Loading from "../components/utils/Loading";
import withAuth from "../hoc/withAuth";
import { APPLICATION_STATUS } from "../utils/constants";

const JobDetails = ({ isRecruiter }) => {
  const [job, setJob] = useState({});
  const [loading, setLoading] = useState(true);
  const [applicationStatus, setApplicationStatus] = useState(null);
  const { jobId } = useParams();

  useEffect(() => {
    getJobDetails(jobId)
      .then((res) => {
        setJob(res.data.job);
      })
      .finally(() => {
        setLoading(false);
      });
    fetchMyApplications();
  }, [jobId]);

  const applyToDirectJob = async () => {
    applyToJob(jobId);
  };

  const fetchMyApplications = async () => {
    const applicationsRes = await getMyApplications();
    const { applications } = applicationsRes.data;
    const application = applications.find((app) => app.jobId === jobId);
    setApplicationStatus(application?.status);
  };

  return loading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
      }}
    >
      <Box
        sx={(theme) => ({
          width: "60%",
          padding: 2,
          flexGrow: 1,
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
          display: "flex",
          [theme.breakpoints.only("md")]: {
            width: "80%",
            padding: 1,
          },
          [theme.breakpoints.down("md")]: {
            width: "90%",
            padding: 1,
          },
        })}
      >
        <Card variant="outlined" sx={{ width: "100%" }}>
          {applicationStatus && (
            <Alert>
              You have already applied to this job. Current status:{" "}
              <strong>{APPLICATION_STATUS[applicationStatus]?.label}</strong>
            </Alert>
          )}
          {isRecruiter ? null : (
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: blue[500] }} aria-label="company_name">
                  {job.companyName.toUpperCase().charAt(0)}
                </Avatar>
              }
              action={
                applicationStatus ? null : job.isDirectJob ? (
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ width: "100%" }}
                    onClick={applyToDirectJob}
                  >
                    Apply
                  </Button>
                ) : (
                  <a
                    href={job.applyLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ width: "100%" }}
                  >
                    <Button variant="contained">Apply</Button>
                  </a>
                )
              }
              title={job.companyName}
            />
          )}
          <CardContent>
            <Typography variant="h5">{job.title}</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Location:
            </Typography>
            <Typography variant="body1">{job.location}</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Salary:
            </Typography>
            <Typography variant="body1">{job.salary} (LPA)</Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{ width: "100%" }}>
          <CardContent>
            <Typography variant="h6" fontWeight={500}>
              About Company:
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: job.companyDescription }} />

            <Typography variant="h6" fontWeight={500}>
              Job Description:
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: job.description }} />
          </CardContent>
        </Card>
        {isRecruiter ? (
          <JobApplications jobId={jobId} />
        ) : applicationStatus ? null : job.isDirectJob ? (
          <Button
            variant="contained"
            size="large"
            sx={{ width: "100%" }}
            onClick={applyToDirectJob}
          >
            Apply
          </Button>
        ) : (
          <a
            href={job.applyLink}
            target="_blank"
            rel="noreferrer"
            style={{ width: "100%" }}
          >
            <Button variant="contained" size="large" sx={{ width: "100%" }}>
              Apply
            </Button>
          </a>
        )}
      </Box>
    </Box>
  );
};

export default withAuth(JobDetails);
