import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_PATH_BACKEND_URL;

export const redirect = (url, returnTo) => {
  if (!returnTo) {
    returnTo = window.location.href;
  }
  window.location = url + "?returnTo=" + returnTo;
};

export const postReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const getReq = async (url, params, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  // const params = new URLSearchParams(queryParams);
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.get(url, { params, headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const delReq = async (url, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.delete(url, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const putReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.put(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.data?.redirectUrl) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};
