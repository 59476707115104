import { Box, Typography, Paper } from "@mui/material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

const Empty = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "90%",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          height: "20%",
          padding: "8%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
        variant="outlined"
      >
        <AllInclusiveIcon fontSize="large" />
        <Typography variant="h6" component="div">
          {message}
        </Typography>
      </Paper>
    </Box>
  );
};

export default Empty;
