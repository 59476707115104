import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  SwipeableDrawer,
} from "@mui/material";
import { grey, teal } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import withAuth from "../hoc/withAuth";
import Logo from "./Logo";
import { MenuButton } from "./NavBar";
import routes from "./routes";

const sidebarItems = {
  apps: [
    {
      title: "Account",
      path: process.env.REACT_APP_AUTH_WEB,
      primary: grey["400"],
      secondary: "white",
    },
    {
      title: "Tests",
      path: process.env.REACT_APP_TEST_WEB,
      primary: teal["400"],
      secondary: teal["50"],
    },
  ],
};

const pages = Object.values(routes).filter((route) => route.sidebar);

const Sidebar = ({ open, toggleDrawer, user }) => {
  const navigate = useNavigate();

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Paper
        elevation={6}
        square
        sx={(theme) => ({
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: 3,
          paddingTop: 1,
          paddingBottom: 1,
          [theme.breakpoints.down("md")]: {
            padding: 2,
            pt: 0.5,
            pb: 0.5,
          },
        })}
      >
        <MenuButton onClick={toggleDrawer} />
        <Logo />
      </Paper>

      <Box
        sx={{
          width: 280,
          padding: 1,
          paddingLeft: 0.5,
        }}
        role="presentation"
        onClick={toggleDrawer}
      >
        <List
          sx={{
            padding: 1,
          }}
          subheader={<ListSubheader>Apps</ListSubheader>}
        >
          {sidebarItems.apps.map(
            ({ title, path, primary, secondary }, index) => (
              <Link
                href={path}
                underline="none"
                target="_blank"
                rel="noreferrer"
                variant="inherit"
                color="inherit"
              >
                <ListItem key={index} disablePadding>
                  <ListItemButton>
                    <ListItemText primary={title} />
                  </ListItemButton>
                </ListItem>
              </Link>
            )
          )}
        </List>
        <Divider />
        <List
          sx={{
            padding: 1,
          }}
          subheader={<ListSubheader>Apps</ListSubheader>}
        >
          {pages
            .filter((p) => p.for?.includes(user?.role))
            .map(({ title, path }) => (
              <ListItem key={title} disablePadding>
                <ListItemButton onClick={() => navigate(path)}>
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default withAuth(Sidebar);
